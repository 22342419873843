import React from 'react';
import { bool, object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  // sections
  SectionHero,
  SectionExplore,
  SectionCategories,
  SectionJoin,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { subscribeToList } from '../../ducks/mailchimp.duck';

import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    onManageDisableScrolling,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isWithListingsLayout = config.landingPageLayout === 'with-listings';

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      currentPage="LandingPage"
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              history={history}
              location={location}
              intl={intl}
              onSubscribeToList={onSubscribeToList}
              subscribeToListInProgress={subscribeToListInProgress}
              subscribeToListError={subscribeToListError}
              isWithListingsLayout={isWithListingsLayout}
            />
          </div>

          <ul className={css.sections}>
            <li className={css.section}>
              <SectionExplore className={css.sectionContent} />
            </li>
            <li className={css.section}>
              <SectionCategories className={css.sectionContent} />
            </li>
            <li className={css.section}>
              <SectionJoin
                className={css.sectionContent}
                onSubscribeToList={onSubscribeToList}
                subscribeToListInProgress={subscribeToListInProgress}
                subscribeToListError={subscribeToListError}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUser: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { subscribeToListInProgress, subscribeToListError } = state.mailchimp;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    subscribeToListInProgress,
    subscribeToListError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubscribeToList: bodyParams => dispatch(subscribeToList(bodyParams)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
